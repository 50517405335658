import Vue from 'vue';

// SASS/CSS
import '../../css/public.scss';

// images
import '../../images/icons.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

new Vue({
    el: '#app',
});
